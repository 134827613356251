.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.center-panel-size {
  width: 340px;
  height: 340px;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
}

.styled-input {
  border: 1px solid var(--grey-3);
  border-radius: 8px;
  margin: 4px 0px;
  padding: 4px 10px;
  min-height: 32px;
}

.login-button {
  min-width: 300px;
  min-height: 42px;
  border-radius: 21px;
  margin: 4px 0px;
}

.container {
  position: relative;
}

.images {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  width: 100%;
  top: -40px;
}

.form-image {
  height: 30px;
}

.form {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--grey-2);
  background-color: var(--white);
  border-radius: 8px;
  padding: 30px;
  min-width: 200px;
  max-width: 400px;
}

.form-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.form-item h1 {
  margin: 0;
}

.form-item label {
  margin-bottom: 5px;
}

.error-message {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-start;
  padding: 12px;
  background-color: #FF000010;
  border-radius: 8px;
}

.error-message i {
  margin-top: 2px;
}

.footer-content {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.copyright {
  margin: 5px;
}
